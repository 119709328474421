import { default as BlogPageDxKyHesGGuMeta } from "/vercel/path0/pages/BlogPage.vue?macro=true";
import { default as BlogPagePreviewiG5NyTA7bmMeta } from "/vercel/path0/pages/BlogPagePreview.vue?macro=true";
import { default as BootcampgUSESVqSsJMeta } from "/vercel/path0/pages/Bootcamp.vue?macro=true";
import { default as ClassRegister1ovZmsd3DurMeta } from "/vercel/path0/pages/ClassRegister/ClassRegister1.vue?macro=true";
import { default as ClassRegister21GyqaygtrRMeta } from "/vercel/path0/pages/ClassRegister/ClassRegister2.vue?macro=true";
import { default as ClassRegister3uHySbFLv9rMeta } from "/vercel/path0/pages/ClassRegister/ClassRegister3.vue?macro=true";
import { default as ClassRegister4SPxrKLycjRMeta } from "/vercel/path0/pages/ClassRegister/ClassRegister4.vue?macro=true";
import { default as ContactUs1egTUxu8n1Meta } from "/vercel/path0/pages/ContactUs.vue?macro=true";
import { default as FAQsUqENcKijw4Meta } from "/vercel/path0/pages/FAQs.vue?macro=true";
import { default as hireTechoFiMwogAcIMeta } from "/vercel/path0/pages/hireTech.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as IntershipP9CWro0t2aMeta } from "/vercel/path0/pages/Intership.vue?macro=true";
import { default as JobPage3ZqX2Hl11bMeta } from "/vercel/path0/pages/JobPage.vue?macro=true";
import { default as JobPortalProfileVisitorAXl4W804neMeta } from "/vercel/path0/pages/JobPortalProfileVisitor.vue?macro=true";
import { default as policyNKohT57BqpMeta } from "/vercel/path0/pages/policy.vue?macro=true";
import { default as Profile7qP0ctJLGcMeta } from "/vercel/path0/pages/Profile.vue?macro=true";
import { default as ReferAndEarn3RZBzfkEFmMeta } from "/vercel/path0/pages/ReferAndEarn.vue?macro=true";
import { default as reset_45password7wK2GPZgVKMeta } from "/vercel/path0/pages/reset-password.vue?macro=true";
import { default as Service00bFGMsvTaMeta } from "/vercel/path0/pages/Service.vue?macro=true";
import { default as SponsorshipV9Rarj4T1IMeta } from "/vercel/path0/pages/Sponsorship.vue?macro=true";
import { default as termsq5qlZHSSonMeta } from "/vercel/path0/pages/terms.vue?macro=true";
export default [
  {
    name: BlogPageDxKyHesGGuMeta?.name ?? "BlogPage",
    path: BlogPageDxKyHesGGuMeta?.path ?? "/BlogPage",
    meta: BlogPageDxKyHesGGuMeta || {},
    alias: BlogPageDxKyHesGGuMeta?.alias || [],
    redirect: BlogPageDxKyHesGGuMeta?.redirect,
    component: () => import("/vercel/path0/pages/BlogPage.vue").then(m => m.default || m)
  },
  {
    name: BlogPagePreviewiG5NyTA7bmMeta?.name ?? "BlogPagePreview",
    path: BlogPagePreviewiG5NyTA7bmMeta?.path ?? "/BlogPagePreview",
    meta: BlogPagePreviewiG5NyTA7bmMeta || {},
    alias: BlogPagePreviewiG5NyTA7bmMeta?.alias || [],
    redirect: BlogPagePreviewiG5NyTA7bmMeta?.redirect,
    component: () => import("/vercel/path0/pages/BlogPagePreview.vue").then(m => m.default || m)
  },
  {
    name: BootcampgUSESVqSsJMeta?.name ?? "Bootcamp",
    path: BootcampgUSESVqSsJMeta?.path ?? "/Bootcamp",
    meta: BootcampgUSESVqSsJMeta || {},
    alias: BootcampgUSESVqSsJMeta?.alias || [],
    redirect: BootcampgUSESVqSsJMeta?.redirect,
    component: () => import("/vercel/path0/pages/Bootcamp.vue").then(m => m.default || m)
  },
  {
    name: ClassRegister1ovZmsd3DurMeta?.name ?? "ClassRegister-ClassRegister1",
    path: ClassRegister1ovZmsd3DurMeta?.path ?? "/ClassRegister/ClassRegister1",
    meta: ClassRegister1ovZmsd3DurMeta || {},
    alias: ClassRegister1ovZmsd3DurMeta?.alias || [],
    redirect: ClassRegister1ovZmsd3DurMeta?.redirect,
    component: () => import("/vercel/path0/pages/ClassRegister/ClassRegister1.vue").then(m => m.default || m)
  },
  {
    name: ClassRegister21GyqaygtrRMeta?.name ?? "ClassRegister-ClassRegister2",
    path: ClassRegister21GyqaygtrRMeta?.path ?? "/ClassRegister/ClassRegister2",
    meta: ClassRegister21GyqaygtrRMeta || {},
    alias: ClassRegister21GyqaygtrRMeta?.alias || [],
    redirect: ClassRegister21GyqaygtrRMeta?.redirect,
    component: () => import("/vercel/path0/pages/ClassRegister/ClassRegister2.vue").then(m => m.default || m)
  },
  {
    name: ClassRegister3uHySbFLv9rMeta?.name ?? "ClassRegister-ClassRegister3",
    path: ClassRegister3uHySbFLv9rMeta?.path ?? "/ClassRegister/ClassRegister3",
    meta: ClassRegister3uHySbFLv9rMeta || {},
    alias: ClassRegister3uHySbFLv9rMeta?.alias || [],
    redirect: ClassRegister3uHySbFLv9rMeta?.redirect,
    component: () => import("/vercel/path0/pages/ClassRegister/ClassRegister3.vue").then(m => m.default || m)
  },
  {
    name: ClassRegister4SPxrKLycjRMeta?.name ?? "ClassRegister-ClassRegister4",
    path: ClassRegister4SPxrKLycjRMeta?.path ?? "/ClassRegister/ClassRegister4",
    meta: ClassRegister4SPxrKLycjRMeta || {},
    alias: ClassRegister4SPxrKLycjRMeta?.alias || [],
    redirect: ClassRegister4SPxrKLycjRMeta?.redirect,
    component: () => import("/vercel/path0/pages/ClassRegister/ClassRegister4.vue").then(m => m.default || m)
  },
  {
    name: ContactUs1egTUxu8n1Meta?.name ?? "ContactUs",
    path: ContactUs1egTUxu8n1Meta?.path ?? "/ContactUs",
    meta: ContactUs1egTUxu8n1Meta || {},
    alias: ContactUs1egTUxu8n1Meta?.alias || [],
    redirect: ContactUs1egTUxu8n1Meta?.redirect,
    component: () => import("/vercel/path0/pages/ContactUs.vue").then(m => m.default || m)
  },
  {
    name: FAQsUqENcKijw4Meta?.name ?? "FAQs",
    path: FAQsUqENcKijw4Meta?.path ?? "/FAQs",
    meta: FAQsUqENcKijw4Meta || {},
    alias: FAQsUqENcKijw4Meta?.alias || [],
    redirect: FAQsUqENcKijw4Meta?.redirect,
    component: () => import("/vercel/path0/pages/FAQs.vue").then(m => m.default || m)
  },
  {
    name: hireTechoFiMwogAcIMeta?.name ?? "hireTech",
    path: hireTechoFiMwogAcIMeta?.path ?? "/hireTech",
    meta: hireTechoFiMwogAcIMeta || {},
    alias: hireTechoFiMwogAcIMeta?.alias || [],
    redirect: hireTechoFiMwogAcIMeta?.redirect,
    component: () => import("/vercel/path0/pages/hireTech.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: IntershipP9CWro0t2aMeta?.name ?? "Intership",
    path: IntershipP9CWro0t2aMeta?.path ?? "/Intership",
    meta: IntershipP9CWro0t2aMeta || {},
    alias: IntershipP9CWro0t2aMeta?.alias || [],
    redirect: IntershipP9CWro0t2aMeta?.redirect,
    component: () => import("/vercel/path0/pages/Intership.vue").then(m => m.default || m)
  },
  {
    name: JobPage3ZqX2Hl11bMeta?.name ?? "JobPage",
    path: JobPage3ZqX2Hl11bMeta?.path ?? "/JobPage",
    meta: JobPage3ZqX2Hl11bMeta || {},
    alias: JobPage3ZqX2Hl11bMeta?.alias || [],
    redirect: JobPage3ZqX2Hl11bMeta?.redirect,
    component: () => import("/vercel/path0/pages/JobPage.vue").then(m => m.default || m)
  },
  {
    name: JobPortalProfileVisitorAXl4W804neMeta?.name ?? "JobPortalProfileVisitor",
    path: JobPortalProfileVisitorAXl4W804neMeta?.path ?? "/JobPortalProfileVisitor",
    meta: JobPortalProfileVisitorAXl4W804neMeta || {},
    alias: JobPortalProfileVisitorAXl4W804neMeta?.alias || [],
    redirect: JobPortalProfileVisitorAXl4W804neMeta?.redirect,
    component: () => import("/vercel/path0/pages/JobPortalProfileVisitor.vue").then(m => m.default || m)
  },
  {
    name: policyNKohT57BqpMeta?.name ?? "policy",
    path: policyNKohT57BqpMeta?.path ?? "/policy",
    meta: policyNKohT57BqpMeta || {},
    alias: policyNKohT57BqpMeta?.alias || [],
    redirect: policyNKohT57BqpMeta?.redirect,
    component: () => import("/vercel/path0/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: Profile7qP0ctJLGcMeta?.name ?? "Profile",
    path: Profile7qP0ctJLGcMeta?.path ?? "/Profile",
    meta: Profile7qP0ctJLGcMeta || {},
    alias: Profile7qP0ctJLGcMeta?.alias || [],
    redirect: Profile7qP0ctJLGcMeta?.redirect,
    component: () => import("/vercel/path0/pages/Profile.vue").then(m => m.default || m)
  },
  {
    name: ReferAndEarn3RZBzfkEFmMeta?.name ?? "ReferAndEarn",
    path: ReferAndEarn3RZBzfkEFmMeta?.path ?? "/ReferAndEarn",
    meta: ReferAndEarn3RZBzfkEFmMeta || {},
    alias: ReferAndEarn3RZBzfkEFmMeta?.alias || [],
    redirect: ReferAndEarn3RZBzfkEFmMeta?.redirect,
    component: () => import("/vercel/path0/pages/ReferAndEarn.vue").then(m => m.default || m)
  },
  {
    name: reset_45password7wK2GPZgVKMeta?.name ?? "reset-password",
    path: reset_45password7wK2GPZgVKMeta?.path ?? "/reset-password",
    meta: reset_45password7wK2GPZgVKMeta || {},
    alias: reset_45password7wK2GPZgVKMeta?.alias || [],
    redirect: reset_45password7wK2GPZgVKMeta?.redirect,
    component: () => import("/vercel/path0/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: Service00bFGMsvTaMeta?.name ?? "Service",
    path: Service00bFGMsvTaMeta?.path ?? "/Service",
    meta: Service00bFGMsvTaMeta || {},
    alias: Service00bFGMsvTaMeta?.alias || [],
    redirect: Service00bFGMsvTaMeta?.redirect,
    component: () => import("/vercel/path0/pages/Service.vue").then(m => m.default || m)
  },
  {
    name: SponsorshipV9Rarj4T1IMeta?.name ?? "Sponsorship",
    path: SponsorshipV9Rarj4T1IMeta?.path ?? "/Sponsorship",
    meta: SponsorshipV9Rarj4T1IMeta || {},
    alias: SponsorshipV9Rarj4T1IMeta?.alias || [],
    redirect: SponsorshipV9Rarj4T1IMeta?.redirect,
    component: () => import("/vercel/path0/pages/Sponsorship.vue").then(m => m.default || m)
  },
  {
    name: termsq5qlZHSSonMeta?.name ?? "terms",
    path: termsq5qlZHSSonMeta?.path ?? "/terms",
    meta: termsq5qlZHSSonMeta || {},
    alias: termsq5qlZHSSonMeta?.alias || [],
    redirect: termsq5qlZHSSonMeta?.redirect,
    component: () => import("/vercel/path0/pages/terms.vue").then(m => m.default || m)
  }
]